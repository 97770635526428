.main {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-wrap: wrap; 
    justify-self: center;
    align-self: center;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}
.main2 {
    height: 100vh;
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-content: center;  
    justify-self: center;
    align-self: center;
}
.title {
    padding-top: 0px;
    display: flex;   
    justify-content: center;    
}
.page {
    padding: 5px;  
    margin: 0px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;       
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    width: 80vw;
    max-width: 300px;
    box-shadow: 0px 0px 10px black;
 
}
.page:hover {
    transition: .01s;
    box-shadow: 0px 0px 2px gray;
}
.createPageBtn{
    color: white;
    padding-top: 4px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    background-color: #8381BD;
    align-self: center;
    justify-self: center;
    align-items: center;    
    justify-content: center;/**centers text within box**/ 
    min-width: fit-content;
    max-width: 106px;
    margin: 10px;
    box-shadow: 0px 0px 3px;
    border-radius: 8px;   
}

.createPageBtn:hover{
    cursor: pointer;
    background-color: #8381BD;
    color: white;
    box-shadow: 0px 0px 6px;
}