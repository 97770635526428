.outside {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    min-height: min-content;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}
.mainPeace{
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    
  
    height: 50px;
    width: 50px;
    cursor: pointer;
    margin-bottom: 30px;
}
.main {
    display: flex;
    padding-bottom: 6px;
    flex-direction: column;
    border-radius:24px;    
    color: rgb(31, 31, 31); 
    justify-self: center;
    align-self: center;
    min-width: 300px;      
    width: 100%;
    max-width: 500px;   
    height: fit-content;   
}
.pageBackground{
    
    height: 100%;
}
.profilePicBorder{
    margin-top: 30px;
    height: 130px;
    width: 130px;      
    align-self: center;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));
    border-radius: 50%;
    display: flex;   
    justify-content: center;  
    overflow-x: hidden;
    margin-bottom: 16px;
}
.profilePic{
    display: flex;    
    width: 90px;
    height: 90px;   
    align-self: center;
    justify-content: center;
    align-items: center;    
}
 .image{
    
   height: 130px;
   width: auto;
}
.name{   
    margin-bottom: 3px;
    max-height: 30px;  
    font-weight: 600;
    font-size: 24px;   
   text-align: center;
   color: #000;
}
.dlContact{
    align-self: center;
    box-shadow: black 0px 0px 1px;
    padding: 2px;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 8px;
    background-color: #8381BD;
    color: white;
    margin-bottom: 6px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}
.dlContact:hover{
    cursor: pointer;
    background-color: greenyellow;
    color: black;
    box-shadow: black 0px 0px 2px;
    
}
.iconArray1{
   
    align-self: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row; 
    
    margin-top: 0px;
    margin-bottom: 9px;
    max-height: fit-content;
  
    
}


.socialCont{

}
.eachBtn{
    
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 6px;

}
.eachIcon{
    height: 50px;
    border-radius: 50%;
    box-shadow: var(--shd,0 1px 2.5px rgba(0,0,0,.6));
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
}
.eachIcon:hover{
    box-shadow: var(--shd,0 2px 3px rgba(0,0,0,.6));
}

.textBox{
    display: flex;
    flex-direction: column;
    height: calc(min-content + 40px);
}

.btnRow{
    
}


.bio{
   /* margin-top: 12px;*/
   margin-bottom: 9px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    
    height: min-content;
    width: max-content;
    max-width: 333px;
    align-self: center;
    display: flex;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;    
    font-family: 'Work Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
color: #2C2C2E;


}
.headline{
    /*margin-top: 6px;*/
    margin-bottom: 6px;
    display: flex;
    flex-wrap: wrap;
    width: content;
    max-width: 90%;
    align-self: center;
    display: flex;
    justify-content: center;
    margin-left: 6px;
    margin-right: 6px;    
    font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 16px;
text-align: center;


}
.linkBox{
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    width: 100vw;
   
}
.linkRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
   
}
.btnCont{
    display: flex;
    justify-content: center;
    align-items: center;
    
    
}
.btn{
    min-width: 200px;
    width: 90vw;
    max-width: 96vw;
    max-width: 333px;
    padding-left: 11px;
    padding-right: 11px;
   
    min-height: 52px;
   
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    height: 20px;
    margin-bottom: 23px; 
    line-height: 18px;
    align-self: center;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;
    border: black;
    border-radius: 18px;
    border-color: black;
    background-color: white;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));

  

}
.btnImgRow{
    display: flex;
    

}
.idk{
    display: flex;
}
.btnOutside{
    display: flex;
    flex-direction: column;    
    justify-content: center;
    align-items: center;
    align-content: center;
    
   
    width: 100%;
}
.btnWithImg{
   
    display: flex;
    flex-direction: column;
    
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;  
    align-self: center;
    align-content: center;
    min-width: 200px;
    width: 90vw;
    max-width: 96vw;
    max-width: 333px;
    
   
    margin-bottom: 23px;
   
    border-radius: 23px;
    border-color: black;
    background-color:white;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));
    cursor: pointer;
    
    padding-left: 11px;
    padding-right: 11px;

}
.btnWithImg:hover{
    cursor: pointer;    
    color: black;  
}
.linkImgCont{
    height: 188px;
    width: 100%;
    max-width: 311px;
    border-radius: 9px;
    margin-top: 11px;
    position: relative;
    align-self: center;
    justify-self: center;
    display: flex;   
    justify-content: center;
   
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: var(--shd, 0 3px 3px rgba(0, 0, 0, 0.11));
    z-index: 5 !important;
   
}
.linkImg{
    
    background-color: white;
    height: 100%;
    z-index: 9 !important;  
}
.imgGrad{
    position: absolute;
    height: 100%;
    width: 100%;
    
    z-index: 11;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0.1) 100%);
    border-radius: 16px;*/
}
.imgGrad:hover{
    /*box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.103) inset;*/
}
.btnImgSubTxt{
    display: flex;
   

    
    width: 100%;
    max-width: 311px;
    margin-top: 3px;
    margin-left: 16px;
   

    font-size: 18px;
    font-weight: 600;
    color: #000;
   
    min-height: 20px;
    height: min-content;
    margin-top: 12px;
    margin-bottom: 16px;
}

.btn:hover{
    cursor: pointer;
   
    color: black;
    border-color: black;
    
}

.authedNav{
   
}
