.main {
    height: 100vh;
    width: 100vw;
    z-index: 0;
    margin-top: 0px;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0);

}



.mainContainer {
    min-height: 100vh;
    margin-top: -60px;
    width: 96vw;
    max-width: 1020px;
    align-self: center;
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 871px) {
    .mainContainer {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 700px) {
    .mainContainer {
        max-width: 100%;

    }
}
@media only screen and (max-width: 650px) {
    .mainContainer {
        margin-top: -90px;

    }
}


.left {
    margin-top: 120px;
    max-width: 460px;
    z-index: 2;
}
@media only screen and (max-width: 500px) {
    .left {
        margin-bottom: -66px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;

    }
}

.right {
    width: 80%;
    max-width: 560px;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 60px;
    position: relative;
    
}

.colesPage {
    max-height: 722px;
    margin-right: -20px;
}

@media only screen and (max-width: 430px) {
    .colesPage {
        max-height: 600px;
       margin-right: -60px;
    }
}

.cardF {
    margin-top: 250px;
    position: absolute;
    max-height: 381px;
    
    margin-right: 46%;
    z-index: 1;
    
}
@media only screen and (max-width: 430px) {
    .cardF {
        margin-top: 200px;
        max-height: 300px;
      margin-right: 33%;
    }
}

.cardB {
    position: absolute;
    max-height: 381px;
    margin-top: 120px;
    margin-right: 350px;
    z-index: 0;
}

.mainPeace {
    align-items: flex-start;
    height: 50px;
    width: 50px;
    cursor: pointer;
}

.bigPeace {
    position: absolute;
    bottom: 0;
    margin-left: auto;
    right: 0;
    z-index: auto;
    max-width: 120vw;

    max-height: fit-content;
}

@media only screen and (max-width: 736px) {
    .bigPeace {
        max-height: 0px;


    }
}




.titleTxt {
    font-size: 60px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 40px;
    color: black;

}

.txt {
    font-size: 52px;
    font-weight: 00;
    color: black;
}
@media only screen and (max-width: 650px) {
    .txt {
        font-size: 40px;
        font-weight: 600;


    }
}
.p {
    color: #303030;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    width: 406px;
    margin-top: 12px;
}
@media only screen and (max-width: 650px) {
    .p {
        font-size: 16px;
        margin-top: 9px;
        line-height: 18px;
        max-width: 96%;
        margin-left: 9px;
        font-weight: 400;
        


    }
}

.blueGrad {
    background: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%),
        linear-gradient(0deg, #A1A1B0, #A1A1B0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}



.buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: flex-start;
    margin-top: 30px;
    z-index: 6;

}
@media only screen and (max-width: 700px) {
    .buttons {
        align-self: center;
        
    }
}
@media only screen and (max-width: 400px) {
    .buttons {
        margin-top: 20px;
        align-self: center;
        
    }
}

.login {
    margin-top: 40px;
    height: 65px;
    width: 80vw;
    max-width: 300px;
    text-align: center;
    align-items: center;
    line-height: 65px;
    font-size: 30px;
    font-weight: 600;
    border-radius: 40px;
    border: 5px solid #9A81FF;
    color: #9A81FF;
    background-color: white;

}
@media only screen and (max-width: 500px) {
    .login {
        margin-top: 20px;
        align-self: center;
        width: 66vw;
        height: 33px;
        line-height: 30px;
        font-size: 23px;
    }
}

.login:hover {
    background: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
    color: white;
    cursor: pointer;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.322);
    border-color: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
}

.signup {
    color: white;
    background: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
    height: 65px;
    width: 80vw;
    max-width: 300px;
    text-align: center;
    align-items: center;
    line-height: 65px;
    font-size: 30px;
    font-weight: 600;
    border-radius: 40px;

}
@media only screen and (max-width: 500px) {
    .signup {
        align-self: center;
        width: 66vw;
        height: 36px;
        line-height: 33px;
        font-size: 23px;
    }
}

.signup:hover {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.322);
    cursor: pointer;
}
.subContainer{
  display: flex;
  flex-direction: column;
  min-height: 200px;
}
.teamSignupBtn {
    all: unset;
    margin-top: 33px;
   align-self: center;
   font-size: 16px;
  


 
  
    font-weight: 600;
    border-radius: 19px;
    border: 5px solid #9A81FF;
    color: #9A81FF;
    background-color: white;
    display: flex;
    padding: 6px;
    align-items: center;


    
}
.teamSignupBtn:hover {
    background: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
    color: white;
    cursor: pointer;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.322);
    border-color: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
}