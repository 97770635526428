.main {
    display: flex;
    padding-bottom: 6px;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    
    border-radius:24px;    
    color: rgb(31, 31, 31); 
    justify-self: center;
    align-self: center;
    min-width: 300px;      
    width: 100vw;
   
    height: fit-content;   
}
.txtCont{
    width: 90%;
    max-width: 600px;
    margin-top: 33px;
    
}
.h1{
    font-size: 33px;
    font-weight: 700;
    color: #212121;
    margin-bottom: 16px;
}
.h2{
    font-size: 23px;
    font-weight: 700;
    color: #212121;
    margin-bottom: 16px;
}
.p{
    font-size: 16px;
    color: #212121;
    margin-bottom: 16px;
    line-height: 21px;
}