.outside {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    height: 100vh;
    /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    background-color: #000;
   
}

.main {
    display: flex;
    padding-bottom: 6px;
    flex-direction: column;
   
    color: rgb(31, 31, 31);
    justify-self: center;
    align-self: center;
    min-width: 300px;
    width: 100%;
    max-width: 500px;
    max-width: 100vw;
    min-height: fit-content;
    height: 100vh;
    justify-items: center;
    padding-top: 52px;
    background-color: #000;
}


.vCard {
    align-self: center;

    height: 100vh;
    min-height: 512px;


    max-height: 512px;
    width: 100%;
    max-width: 316px;


}

.name {
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;

    margin-top: 137px;
    margin-left: 26px;
}

.qr {
    margin-left: 28px;
    margin-top: 133px;

}
.navBtnRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 345px !important;
    min-height: 50px !important;
    height: 50px !important;
    justify-self: center;
    align-self: center;
    background-color: none;
   

}

.rightBtnGroup {
    
    display: flex;
    justify-content: space-between;
}

.navBtn {
    align-self: flex-end;
    justify-self: right;
    all: unset;
    color: #E9E9E9;
    background-color: #363636;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
}