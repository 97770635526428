.main {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: column;
    justify-items: center;
    align-items: center;
}
.main2 {
    height: 100vh;
    display: flex;
    flex-direction: column;    
}
.title {
    padding-top: 0px;
    display: flex;   
    justify-content: center;    
    margin-bottom: 9px;
}
.page {
    padding: 5px;  
    margin: 0px;
    border-end-end-radius: 8px;
    border-end-start-radius: 8px;       
    font-size: 20px;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-self: center;
    width: 80vw;
    max-width: 300px;
    box-shadow: 0px 0px 10px black;
 
}
.page:hover {
    transition: .01s;
    box-shadow: 0px 0px 2px gray;
}
.createPageBtn{
    align-self: center;
    justify-self: center;
    align-items: center;
    display: flex;
    height: 33px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 9px;
    padding-right: 9px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;   
}

.createPageBtn:hover{   
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}
.filterCenter{
    display: flex;
    justify-items: center;

}
.editDeptBtn{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;
    height: 23px;
    line-height: 23px;
    min-width: fit-content;
    background-color: #A082FC;

    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

}
.editDeptBtn:hover{
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}