.main2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    max-width: 100vw !important;
    overflow-x: hidden;
}

.main {
    height: fit-content;
    width: 100vw;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-self: center;
    align-items: center;

    justify-self: center;
    align-self: center;
    padding-bottom: 16px;
    margin-top: 9px;
    margin-bottom: 16px;
}

.mainC {
    margin-top: 33px;
    height: fit-content;
    width: 98vw;
    max-width: 500px;
    min-height: 80vh;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-self: center;
    align-items: center;
    background-color: white;
    justify-self: center;
    align-self: center;
    padding-bottom: 16px;
    margin-bottom: 16px;

}

.inputs {
    border: none;
}

.centerItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
}

.h1 {
    color: #9a81ff;
    margin: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    text-align: center;
    max-width: 370px;
}

.swapEdit {
    display: flex;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    justify-self: center;
    align-content: center;
    background-color: #A082FC;
    color: white;
    border-radius: 6px;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    box-shadow: var(--shd, 0 1px 2px rgba(0, 0, 0, .6));
    cursor: pointer;
}

.editPencil {
    margin-left: 6px;
}

.usernameBox {
    width: 200px;
    height: 44px;
    left: 108px;
    top: 95px;

}

.pageNameTitle {
    padding-left: 7px;

    height: 14px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2C2C2E;
}

.pageName {
    display: flex;
    margin-top: 16px;

    padding: 6px;
    min-width: fit-content + 10px;
    width: 200px;

    align-self: center;


    background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    text-align: left;
    align-items: left;
    border-radius: 9px;

    padding-left: 6px;
    font-size: 14px;
}

.inputNameHeadline {
    display: flex;

    min-width: fit-content + 10px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    margin-top: 2px;
    margin-bottom: 6px;
    padding: 6px;
    background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    text-align: left;
    align-items: left;
    border-radius: 9px;

    padding-left: 6px;
    font-size: 14px;
}



.topPage {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 4px;
}

.profilePicBorder {
    margin-top: 12px;
    max-height: 40%;
    max-width: 60%;
    min-width: 90px;
    min-height: 90px;
    align-self: center;

    box-shadow: 0px 0px 3px;
    border-radius: 50%;
    margin-bottom: 6px;
    overflow: hidden;
}

.profilePic {
    display: flex;

    width: 100px;
    height: 100px;

    align-self: center;
    justify-content: center;
    align-items: center;

}

.profilePicBox {
    width: 110px;
    height: 110px;
}

.image {
    height: 100%;
    width: auto;
}

.addPic {
    display: flex;
    flex-direction: column;
    align-self: center;
    height: 42px;
    width: 42px;
    margin-bottom: 10px;
    color: white;
    background-color: #A082FC;
    border: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
}

.infoInputsBox {
    width: 260px;

    display: flex;
    flex-direction: column;
    align-self: center;
}

.infoInputsBox2 {
    width: 260px;
    margin: 23px;
    display: flex;
    flex-direction: column;
    align-self: center;
}

.contactContainer {
    display: flex;
    min-width: fit-content;
    padding-left: 3px;
    padding-right: 3px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
}

.vcfBtns {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    align-items: center;
    text-align: center;
    justify-content: center;

    display: flex;
    height: 23px;
    line-height: 23px;
    min-width: fit-content;
    background-color: #A082FC;

    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;



}

.vcfBtnUpload {
    align-self: center;
    justify-self: center;
    align-items: center;
    display: flex;
    height: 23px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

}

.vcfBtns:hover {
    cursor: pointer;
}

.title {
    margin-top: 16px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

}

.titleS {
    margin-top: 0px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

}

.nameBioInputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    height: max-content;
}

.p1 {
    align-self: center;
    margin-top: 16px;
    margin-bottom: 6px;
    font-weight: 500;
}

.fullName {
    height: 30px;
    align-self: center;


    background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    text-align: left;
    align-items: left;
    border-radius: 9px;
    place-items: left;
    padding-left: 6px;
    font-size: 14px;
}

.biography {
    align-self: center;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    width: 260px;
    background-color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    text-align: left;
    align-items: left;
    border-radius: 9px;
    place-items: left;
    padding-left: 6px;
    font-size: 14px;
    overflow-wrap: break-word;
    margin-top: 6px;
    resize: none;
}

.txtA {
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    color: black;
    background-color: white;
    border: none;
    border-radius: 9px;
    resize: none;
    padding-left: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 6px;
    background-color: white;
    border: none;
    text-align: left;
    align-items: left;
    border-radius: 9px;
    padding-left: 6px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}

.txtInput {
    margin-top: px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    background-color: #F2F2F2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    width: 220px;
    line-height: 15px;
    resize: none;
}

.txtInput :focus {
    border: 2px solid #9a81ff;
    outline: none;
    background-color: rgb(230, 230, 230);
}

.txtInput :hover {
    background-color: rgb(230, 230, 230);
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(29, 29, 29);
    opacity: 1;
}

.inputBio {
    margin-top: px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    background-color: #F2F2F2;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    min-height: 100px;
    width: 220px;
    line-height: 14px;
    resize: none;
}


.socialBox {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    min-height: min-content;
    margin-bottom: 6px;
    max-width: 100%;
}

.socialBtnTitle {
    align-self: center;
}


.socialPop {
    position: fixed;
    top: 23vh;
    left: 50vw;
    margin-left: -150px;
    width: 300px;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-content: center;
    background-color: white;
    max-width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
}

.popBackground {
    align-self: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.333);
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10 !important;
}


.linkInputs {
    position: fixed;
    top: 23vh;
    left: 50vw;
    margin-left: -150px;
    width: 300px;
    min-height: 290px;
    border: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    background-color: white;
    max-width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
    z-index: 10 !important;


}

.linkInputs input {
    color: black;
}

.linkInputBox {
    display: flex;
    flex-direction: column;
    width: 260px;
    align-self: center;
    margin-bottom: auto;
    margin-top: 12px;

}

.currentLinkName {
    align-self: center;

    width: 90%;

    height: 20px;
    box-shadow: 0px 0px 2px;
    margin: 2px;
}

.currentLinkUrl {
    align-self: center;
    justify-self: center;
    display: block;
    width: 90%;

    height: 20px;
    box-shadow: 0px 0px 2px;
    margin: 2px;
}

.submitCont {
    display: flex;
    flex-direction: column;
    height: 60px;
    justify-items: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.submit {

    align-self: center;
    width: 140px;
    height: 36px;
    text-align: center;
    background-color: #9a81ff;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
}

.submit {
    cursor: pointer;
    margin-top: auto;
}

.addPage {
    min-width: fit-content;
    height: 20px;
    margin-top: 2px;
    padding: 2px;
    padding-right: 4px;
    padding-left: 4px;
    justify-content: center;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #A082FC;
    color: white;
    box-shadow: 0px 0px 8px;
}

.submit:disabled {
    background-color: #FF3B30;
    cursor: not-allowed;
}

.addBtn:disabled {
    cursor: not-allowed;
}

.deleteSocial {
    background-color: #FF3B30;
    color: white;
    border-radius: 50%;
    height: 1;
    width: 1;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    padding: 3px;
}






.iconArray {
    margin-top: 10px;
    margin-bottom: 10px;
    align-self: center;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 20px;
    justify-content: center;


    max-width: 100%;
    padding: 2px;

}

.iconArray1 {
    margin: 2px;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 90%;
    padding: 2px;
}

.colorRadio {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    margin-bottom: 6px;
}

.iconBackdrop {

    height: 3rem;
    width: 3rem;
    margin: 2px;
    padding: 2px;
    border-radius: 50%;
    cursor: pointer;
}

.eachBtn {

    padding: 6px;
}

.eachBtn :hover {
    box-shadow: 0px 0px 3px;
}

.eachIcon {
    height: 50px;
    border-radius: 50%;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
}

.eachIcon :hover {
    box-shadow: 0px 0px 3px 0px;



}

.socialParams {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 100%;
    align-self: center;
    align-content: center;
    justify-content: center;

}

.socialName {
    align-self: center;
    justify-self: center;
    font-weight: 500;
    font-size: 16px;
}

.socialURL {
    border: none;
    box-shadow: 0px 0px 4px;
    margin: 4px;
    min-width: 100px;
    max-width: 100%;
    height: 20px;
    border-radius: 4px;
}

.closeCont {
    width: 100%;
}

.closeSocialPop {
    margin-top: 12px;
    margin-left: 86%;
    right: 0;
    cursor: pointer;
}

.openSocialBox {
    align-self: center;
    justify-self: center;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    height: 30px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    width: 206px;
    margin-top: 16px;
}

.addSocialBtn {
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #34C759;
    color: white;
    box-shadow: 0px 0px 8px;
    height: 30px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 40px;
    margin-top: 16px;
    width: 206px;
}

.addSocialBtn:disabled {
    background-color: #FF3B30
}

.addSocialBtn:disabled {
    cursor: not-allowed;
}

.links {
    margin-top: 20px;
    color: black;
    display: flex;
    grid-column: auto;

}


.linkAdded {

    display: flex;

    align-items: center;
    align-self: center;
    justify-self: center;
    box-shadow: 0px 0px 4px;
    border-radius: 6px;
    min-width: 200px;
    grid-template-columns: auto;
    margin-bottom: 8px;
}

.linkName {
    display: flex;
    flex: 1;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-self: center;
    margin-left: 6px;
}

.deleteLink {
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    flex-basis: auto;
    height: 100%;
    vertical-align: middle;

    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    background-color: red;
}

.drag {
    display: flex;
    flex: 0;
    flex-basis: auto;
    flex-wrap: wrap;
    margin-left: 6px;
    margin-right: 6px;

}

.deleteLink,
.drag :hover {
    cursor: pointer;
}

.pageLinkTxt {
    box-shadow: 0px 0px 3px;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.draggable {
    width: 100%;
}

.draggableS {
    margin-top: 9px;
    width: 100%;
}

.error {
    margin: 9px;
    margin-top: -9px;
    font-weight: 400;
    color: #FF3B30;
    text-align: center;
}

.splitRow {
    display: flex;
    align-self: center;
    align-items: center;
    margin-top: 12px;
}

.splitRow2 {
    display: flex;
    align-self: center;
    align-items: center;
    margin-top: 2px;
}

.marginRight {
    padding-right: 3px;
    height: 14px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2C2C2E;
}

.txt {
    align-self: center;
    justify-self: center;
    margin-right: 6px;
    text-align: center;
    line-height: 100%;
    font-size: 14px;
    font-weight: 400;
}

.switch {
    color: #9a81ff;
    size: 1;
    z-index: 0;
}

.saveRCont {
    position: fixed;
    z-index: 10;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 56px;

}

.saveRBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;

    backdrop-filter: blur(6px);
    z-index: 1;
    width: 66%;
    height: 56px;
    border-radius: 0px 0px 25px 25px;
    padding-left: 9px;
    padding-right: 9px;

}

.saveRow {
    display: flex;
    justify-content: space-evenly;


}

.save {
    width: 60%;
    max-width: 115px;
    all: unset;
    height: 23px;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 3px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    animation: shake 0.8;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;

}

.cancel {
    all: unset;

    width: 30%;
    max-width: 77px;
    text-align: center;
    height: 21px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    padding-left: 3px;
    padding-right: 3px;
    border: rgba(0, 0, 0, 0.185);
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;

    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;

}

@keyframes shake {
    0% {
        transform: translateX(0)
    }

    25% {
        transform: translateY(-3px);
    }


    50% {
        transform: translateY(3px);
    }


    100% {
        transform: translateX(0px);
    }
}

.changeBtn {

    all: unset;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    font-size: 12.5px;
    font-weight: 450;
    height: 33px;
    width: 60%;
    text-align: center;
    margin-top: 9px;
    border-radius: 9px;
    cursor: pointer;
    align-self: center;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;




}

.changeBtn:hover {
    background-color: rgb(231, 229, 229);
}

.changeColorTxt {
    flex-grow: 1;
    text-align: center;

}

.changeColorLogo {
    margin-left: -20px;
    margin-right: 6px;
}

.navBtnRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 345px !important;
    height: 50px;
    justify-self: center;
    align-self: center;

}

.rightBtnGroup {

    display: flex;
    justify-content: space-between;
}

.navBtn {
    align-self: flex-end;
    justify-self: right;
    all: unset;
    color: #363636;
    background-color: #E9E9E9;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
}

.saveBtn {
    all: unset;
    color: #E9E9E9;
    background-color: #007AFF;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
    width: 75px;
    text-align: center;

}