.main {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.dragDrop {}

.droppable {}

.char {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.list {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    margin-right: 6px;
    max-height: 64.35px;
    max-width: 44.35px;
}

.eachIcon {
    height: 50px;
    border-radius: 50%;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));
    border-radius: 50%;
    padding: 6px;
    
}

.eachIcon:hover {
    animation: shake 0.8s;
}

@keyframes shake {
    0% {
        transform: translateX(0)
    }

    25% {
        transform: translateY(-3px);
    }
    

    50% {
        transform: translateY(3px);
    }
   
    100% {
        transform: translateX(0px);
    }
}

.editBtn{
    cursor: pointer;
}
.editCont{
    position: absolute;
    
    width: 120px;
}
.editBox{    
    position: absolute;
    right: 20px !important;
    display: flex;
    flex-direction: column;
   
    background-color: white;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));
    border-radius: 6px;
    cursor: default;
    
    width: 120px;
    min-width: 120px;
    margin-top: 50px;
    margin-right: 54px;
    
}
.editClose{
    cursor: pointer;
    align-self: flex-end;
    margin-top: 3px;
    margin-right: 3px;
    margin-bottom: 6px;

    
}
.editUrlBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 3px;   
    border-bottom: solid rgba(0, 0, 0, 0.103) .5px;
    border-top: solid rgba(0, 0, 0, 0.034) .5px;
    font-size: 14px;
    font-weight: 500;
    height: 33px;
    padding-left: 6px;
    padding-right: 6px;
}
.deleteBtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    font-size: 14px;
    font-weight: 500;
    height: 33px;
    padding-left: 6px;
    padding-right: 6px;
}
.pencil{
   margin-left: auto;
}
.trash{
    margin-left: auto;
}
.socialPop {
    position:fixed;
    top: 20px;
    left: 50vw;
    margin-left: -150px;
    width: 300px;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-content: center;
    background-color: white;
    max-width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
    z-index: 11;
}
.popBackground{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.333);
    top: 0;
    min-height: 200vh;
    width: 100vw;
}
.closeCont {
    width: 100%;
}
.closeSocialPop {
    margin-top: 12px;
    margin-left: 86%;
    right: 0;
    cursor: pointer;
}
.titleS {
    margin-top: 0px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

}
.txtA {
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    color: black;
    background-color: white;
    border: none;    
    border-radius: 9px;
    resize: none;
    padding-left: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 6px;
    background-color: white;
    border: none;    
    text-align: left;
    align-items: left;
    border-radius: 9px;
    padding-left: 6px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}
.center{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 9px;

}
.addSocialBtn {
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #34C759;
    color: white;
    box-shadow: 0px 0px 8px;
    height: 30px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 40px;
    margin-top: 9px;
    width: 206px;
}

.addSocialBtn:disabled {
    background-color: #FF3B30
}

.addSocialBtn:disabled {
    cursor: not-allowed;
}
.changeBtn{
    all: unset;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    font-size: 12.5px;
    font-weight: 450;
    height: 33px;
    width: 60%;
    text-align: center;
    margin: 9px;
    border-radius: 9px;
    cursor: pointer;
   
   
}
