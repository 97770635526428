.main2 {
   
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    top: 0;
    width: 100vw;
    max-width: 500px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));
    border-radius: 0px 0px 33px 33px;
    z-index: 9 !important;
}

.main {
    justify-self: center;
    align-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
    align-items: center;
    

}

.peaceIco {
    margin-left: 16px;
    height: 45px;
    cursor: pointer;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-content: center;

}

.accountDropToggle {
    cursor: pointer;
    margin-right: 16px;
    display: flex;

    height: 43px;
    width: 43px;
    border: rgba(0, 0, 0, 0.515);
    border-width: .6px;
    border-style: solid;
    border-radius: 50%;
    justify-content: center;

    overflow-x: hidden;
}

.image {
    min-height: 100%;
    max-height: 100%;
    width: auto;
}

.float {
    position: absolute;
    box-shadow: 0px 6px  6px rgba(0, 0, 0, 0.192);
    border-radius: 0px 0px 25px 25px;
    top: 0;
    margin-top: 36px;
    
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 500px;
    z-index: -1 !important;

  
    background-color: white;
  
}


.accountDrop {
    margin-top: 33px;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    justify-content: center;
    justify-items: center;
}

.accountBtn {
    display: flex;
    justify-content: center;
    cursor: pointer;
    min-width: 250px;
    background-color: white;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));
    border-radius: 6px;
    height: 26px;
    align-items: center;
    margin-bottom: 6px;
}
.greyBtn{
    font-size: 23px;
    font-weight: 600;
    height: 52px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding-right: 33px;
    background-color: #F2F2F7;
}
.greyBtn:hover{
    background-color: #cecece;

}
.whiteBtn{
    font-size: 23px;
    font-weight: 600;
    height: 52px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding-right: 33px;
    
}
.whiteBtn:hover{
    background-color: #dddddd;
}

.accountBtn:hover {
    box-shadow: var(--shd, 0 1px 2.5px 1px rgba(0, 0, 0, 0.7));


}

.logout {
   
    font-size: 23px;
    font-weight: 600;
    height: 52px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    padding-right: 33px;
    background-color: #ff3a30c9;
    border-radius: 0px 0px 25px 25px;
}

.logout:hover {
    background-color: #FF3B30;
}