.main {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    justify-content: center;/**centers text within box**/ 
    width: 90vw;
    max-width: 350px; 
    height: 33px;
    margin-bottom: 12px;
   
    border-radius: 9px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    
}



.title {
    font-size: 22px;
    padding: 5px;
    flex: 1;
    flex-basis: 70%;
    box-sizing: border-box;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.delete{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}


.edit,
.view,
.delete,
.send {
    color: whitesmoke;
    padding: 5px;
    flex: 1;
    flex-basis: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    
    color: rgba(0, 0, 0, 0.801);

}
.send:hover,
.edit:hover,
.view:hover
 {
 color: #34C759;
}
.delete:hover{
    color: #FF3B30;
}
.page{
    box-shadow: 0px 0px 8px;
}

