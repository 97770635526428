.popBackground{
  position: absolute;
  background-color: rgba(0, 0, 0, 0.333);
  top: 0;
 
  min-height: 200%;
  width: 100vw;
}
   
  .box{
    position: relative;
    top: 60px;
    left: 50vw;
    margin-left: -150px;
    width: 300px;
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-content: center;
    background-color: white;
    max-width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
    padding-left: 9px;
    padding-right: 9px;
    z-index: 1;
  }
   

  .closeCont {
    width: 100%;
    height: 33px;
}
.closeSocialPop {
  margin-top: 10px;
  margin-left: 90%;
  right: 0;
  cursor: pointer;
}
  .closeIcon:hover{
    background-color: red;
  }
  .titleS {
    margin-top: 6px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

}
  .profilePicBorder{    
    width: 60px;
    height: 60px;       
    align-self: center;    
    box-shadow: 0px 0px 3px;
    border-radius: 50%;
    margin-top: 3px;
    margin-bottom: 3px;
    overflow:hidden;
    align-items: center;
}
.profilePic{
    display: flex;  
    height: 100%;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    
}

  .pageName{
    align-self: center;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 6px;
  }
  .form{
    margin-top: 9px;
    margin-bottom: 9px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .numTitle{
   
    height: 14px;
    padding-left: 6px;
    margin-bottom: 3px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2C2C2E;
  }
  .input{
    background: #ffffff;
	border: 1px solid #CACACA;
	border-radius: 9px;
	margin-bottom: 6px;
	height: 29px !important;
  max-height: 33px !important;

  max-width: 300px !important;
	outline: none;
	box-shadow: rgba(0, 0, 0, 0.289) 0px 1px 3px;
  font-size: 15px;
   padding-left: 6px;
   -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;

  }

  .input:focus{
    border-color: #A082FC;
  }
  .upload{
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #34C759;
    color: white;
    box-shadow: 0px 0px 8px;
    height: 30px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 9px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 12px;
   
    width: 206px;
  }
  .upload:disabled {
    background-color: #FF3B30;
    cursor: not-allowed;
}