.outside {
    display: flex;
    flex-direction: column;
    
   
    width: 100vw;
    max-width: 100vw;
    
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    min-height: fit-content;

}
.main {
    display: flex;
   
    flex-direction: column;
    border-radius:24px;    
    color: rgb(31, 31, 31); 
    
    justify-self: center;
    align-self: center;
         
    width: 100%;
    max-width: 500px;
    
    height: 100vh;
    min-height: 630px;
  
}
.title {
    margin-top: 16px;
    align-self: center;
    font-size: 26px;
    font-weight: 700;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.104);
}
.btnContainer{
    display: flex;
    min-width: fit-content;
    width: 100%;
    justify-content: space-evenly;
    
    height: 80vh;
    min-height: 500px;
    max-height: 600px;
    justify-self: center;
    align-self: center;
    
    
    
}
.leftCol{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: fit-content;
    

}
.rightCol{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: fit-content;

}

.sqrBtn{
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .25);
    height: 155px;
    width: 155px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 12px;
   
    
   
}
.sqrBtn:hover{
    background-color: rgba(252, 252, 252, 0.734);
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.116);

}
.iconBox{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 70px;
    margin-left: 16px;
    margin-top: 16px;
    margin-right: auto;


}
.icon{
    height: 100%;
}
.txtBox{
    font-weight: 600;
    color: #000000;
    font-size: 20px;
    margin-left: 9px;
    margin-bottom: 20px;
    margin-top: auto;
    
}
.viewTeamDash{
    display: flex;
    margin-top: 16px;
    margin-bottom: -6px;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 26px;
    min-width: fit-content;
    background-color: #34C759;
    align-items: center;
    color: white;
    border: none;
    border-radius: 6px;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

}
.viewTeamDash:hover{
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}
.teamIcon{
    margin-right: 6px;
}





