.main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #FFF;

}

.cInfoPop {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    height: 100vh;
    width: 100vw;

}
.selectedName{
    margin-top: 18px;
    font-size: 33px;
    font-weight: 700;
    text-align: center;
}
.cInfoCont {
    margin-top: 33px;
    width: 90vw;
    max-width: 333px;
    

    border-radius: 9px;
    background: #F4F4F4;
    justify-self: center;
    align-self: center;
    box-shadow: var(--shd, 0 .8px 2.9px rgba(0, 0, 0, .6));
}
.cInfoLabel{
    font-weight: 600;
}
.cInfoRow {
    display: flex;
    padding-top: 6px;
    padding-left: 9px;
}
.cInfoRowA {
    display: flex;
    padding-top: 6px;
    padding-left: 9px;
    height: 100px;
}
.cInfoTxt{
    margin-left: 18%;
}
.cInfoTxtBx{
    margin-left: 18%;
    height: 100px;
}

.navBtnRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 345px !important;
    height: 50px;
    justify-self: center;
    align-self: center;

}

.rightBtnGroup {
    width: 133px;
    display: flex;
    justify-content: space-between;
}

.navBtn {
    align-self: flex-end;
    justify-self: right;
    all: unset;
    color: #363636;
    background-color: #E9E9E9;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
}

.dBtn {
    all: unset;
    color: #E9E9E9;
    background-color: #007AFF;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;

}

.searchBox {
    all: unset;
    justify-self: center;
    align-self: center;
    padding-left: 6px;
    width: 80vw;
    max-width: 337px !important;
    height: 33px !important;
    max-height: 31px !important;
    border-radius: 9px;
    border: 1px solid #BAB7B7;
    color: #606060;


}

.title {
    margin: 20px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 600;
}

.columnContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-self: center;
    align-self: center;
    min-height: 200px;
    min-height: fit-content;
    max-height: max-content;
    box-shadow: 0 18px 4px 0px white, 0 -18px 4px 0px white, 1px 0 15px -4px #000000, -1px 0 15px -4px #000000;

}

.column {
    min-width: fit-content;
    width: 100vw;
    max-width: 500px;
    min-height: fit-content;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-right: 26px;
    margin-left: 26px;
    border-radius: 24px;

    color: rgb(31, 31, 31);
    background-color: rgb(255, 255, 255);



}

.groupedDateContainer {
    width: 100%;

}

.groupedDateStr {
    padding-left: 9px;
    font-size: 16px;
    font-weight: 700;
}

.contactContainer {

    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: right;
    cursor: pointer;

    border-bottom: solid rgba(31, 31, 31, 0.179);
    border-width: .5px;





}

.contactName {
    font-family: 'Work Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;

    align-self: center;
    margin-left: 24px;
    margin-right: auto;


}

.downloadLogo {
    height: 26px;
    align-self: center;
    margin-right: 6px;
    cursor: pointer;


}

.downloadLogo:hover {
    color: #34C759;


}

.deleteLogo {
    margin-top: auto;
    margin-bottom: 33px;
    height: 33px;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 9px;
    padding-bottom: 9px;
    align-self: center;
    margin-right: 6px;
    cursor: pointer;
    background-color: #FF3B30;
    border-radius: 50%;
    box-shadow: var(--shd, 0 .8px 2.9px rgba(0, 0, 0, .6));
    

}

.deleteLogo:hover {
    color: #E8E8E8;
    background-color: #000000;

}