.unAuthedNav{
 display: grid;
 grid-template-columns: 10rem 10rem 10rem;
  grid-gap: 1rem;
 grid-auto-flow: row;
}
.Grid{
    max-height: 100px;
}
.btnBox{
    display: flex;
    flex-direction: row;
    justify-self: right;
    
    
    height: 100%;
    align-items: center;
    margin-right: 10px;
}
.btn{
    margin: 10px;
    align-self: center;
    align-items: center;
    cursor: pointer;
   
}
