.contactRow {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: -30px;
    width: 100%;
    max-width: 400px;
    align-self: center;
}
.xchangeBackground{
    align-self: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.333);
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10 !important;
    display: flex;
    flex-direction: column;
}

.contactInfo {
    background-color: #34C759;
    margin-top: 22px;
    margin-right: 3px;
    width: 140px;
    max-width: 140px !important;
    height: 33px;
    max-height: 33px !important;
    color: white;
    border-radius: 9px;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 27px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: 'Work Sans';
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));
}

.contactInfo:hover {
    box-shadow: var(--shd, 0 3px 3.3px rgba(0, 0, 0, 0.5));
}

.xConBtn {
    background-color: #007AFF;
    margin-top: 22px;
    margin-right: 3px;
    width: 190px;
    max-width: 190px !important;
    height: 33px;
    max-height: 33px !important;
    color: white;


    border-radius: 9px;
    align-content: center;
    align-items: center;
    cursor: pointer;

    margin-bottom: 27px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-family: 'Work Sans';
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    box-shadow: var(--shd, 0 2px 3.3px rgba(0, 0, 0, 0.5));
}

.xConBtn:hover {
    box-shadow: var(--shd, 0 3px 3.3px rgba(0, 0, 0, 0.5));
}