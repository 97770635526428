.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title{
    text-align: center;
    font-size: 33px;
    font-weight: 800;
    margin-top: 33px;
}
.subTitle{
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    color: #636363;
    margin-top: 9px;
}
.recurringContainer{
    display: flex;
    justify-content: center;
    margin-top: 9px;
}
.radioSelector{
    all: unset;
    border: none;
}
