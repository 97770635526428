.main {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #2C2C2E;
}

.dragdropcontext {}

.droppable {}

.draggable {}

.char {
    margin-top: 16px;






}


.list {
    min-width: 200px;
    width: 90vw;
    max-width: 96vw;
    max-width: 333px;
    display: flex;
    flex-wrap: wrap;
    border: black;
    border-radius: 9px;
    border-color: black;
    background-color: white;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 16px;
    min-height: 30px;
}

.link {
    min-width: 200px;
    width: 90vw;
    max-width: 96vw;
    max-width: 333px;
    padding-left: 11px;
    padding-right: 11px;
    display: flex;


    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    flex-wrap: wrap;
    border: black;
    border-radius: 9px;
    border-color: black;
    background-color: white;
    box-shadow: var(--shd, 0 1px 2.5px rgba(0, 0, 0, .6));


    margin-bottom: 16px;
    min-height: 30px;
}

.linkRow {
    display: flex;
    justify-content: space-between;
    height: 52px;
    width: 100%;
}

.dragImgCont {
    height: 188px;
    width: 100%;
    max-width: 311px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 9px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}

.dragImgContEdit {
    height: 72px;
    width: 147px;
    max-width: 311px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 9px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}

.dragImg {

    min-height: 100%;
    height: fit-content;
    max-height: 110%;
    width: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.dragImgEdit {

    min-height: 100%;
    height: fit-content;
    max-height: 110%;
    width: auto;
    overflow-x: hidden;
    overflow-y: hidden;
}

.leftContImgEdit {
    height: 100%;
    min-width: 90px;
    width: 40%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
}

.imgBtnRow {}

.btnOutside {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;


}

.btnIconRow {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-top: 0px;
    min-height: 24;

}

.linkWithImg {
    display: flex;

    flex-wrap: wrap;
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    align-self: center;
    align-content: center;
    justify-content: center;
    min-width: 200px;
    width: 90vw;
    max-width: 96vw;
    max-width: 333px;
    padding-left: 6px;
    padding-right: 6px;
    min-height: 200px;
    margin-bottom: 16px;

    border-radius: 21px;
    border-color: black;
    background-color: white;
    box-shadow: var(--shd, 0 .8px 2.9px rgba(0, 0, 0, .6));
    cursor: pointer;


}

.linkImgCont {
    position: relative;
    align-self: center;
    justify-self: center;
    display: flex;

    justify-content: center;
    height: 140px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 16px;
    margin-top: 3px;
    z-index: 5 !important;
    box-shadow: var(--shd, 0 1px 3px -1px rgba(0, 0, 0, 0.315));
}

.linkImg {

    background-color: white;
    height: 100%;
    z-index: 9 !important;
}

.btnImgSubTxt {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 3px;
    margin-left: 16px;
    margin-bottom: -6px;
}

.navBtn {
    align-self: flex-end;
    justify-self: right;
    all: unset;
    color: #363636;
    background-color: #E9E9E9;
    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
}

.pageName {
    font-size: medium;
    font-weight: bold;
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;

}

.deletBx {
    align-items: center;
    align-self: center;
    display: flex;

    justify-content: center;
    align-content: center;
    justify-items: center;
    height: 100%;
}

.deleteBx:hover {
    cursor: pointer;

}

.deleteIco {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.802);
}

.deleteIcoBtn {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.802);

}

.popBackground {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.76);
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

    align-items: center;
    cursor: none;
}

.linkInputs {
    top: 0;
    position: relative;
    background-color: #FFF;
    width: 100vw;
    height: 100vh;

    padding-left: 9px;
    padding-right: 9px;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.swapCont {
    all: unset;
    position: fixed;
    background-color: #2FC154;
    color: #FFF;
    height: 33px;
    width: 33px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 150px;
    margin-left: 260px;
}
.swapCont2 {
    all: unset;
    z-index: 10;
    background-color: #2FC154;
    color: #FFF;
    height: 33px;
    width: 33px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 150px;
    margin-left: 260px;
}
.dragIco {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    justify-self: center;
    color: rgba(0, 0, 0, 0.802);
    cursor: pointer;
}
.dragIcoBtn {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: center;
    justify-self: center;
    color: rgba(0, 0, 0, 0.802);
    cursor: pointer;
}
.closeCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 345px !important;
    height: 50px;
    justify-self: center;
    align-self: center;
}
.closeSocialPop {}

.titleS {
    font-weight: 700;
    font-size: larger;
    margin-top: 33px;
}
.pageNameTitle {
    padding-left: 9px;
    color: #2C2C2E;
}
.inputBio {
    align-self: center;
    justify-self: center;
    margin-top: px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    background-color: #F2F2F2;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    min-height: 100px;
    width: 220px;
    line-height: 14px;
    resize: none;
}
.txtA {
    font-size: 14px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    color: black;
    background-color: white;
    border: none;

    border-radius: 9px;
    resize: none;
    padding-left: 6px;


    margin-top: 6px;

    padding: 6px;
    background-color: white;
    border: none;

    text-align: left;
    align-items: left;
    border-radius: 9px;

    padding-left: 6px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
}

.submit {
    all: unset;
    align-self: center;
    display: flex;


    min-width: fit-content;
    height: 23px;
    color: #E9E9E9;
    background-color: #007AFF;
    align-items: center;

    border-radius: 6px;
    height: 24px;
    padding-left: 9px;
    padding-right: 9px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;


}

.inputContainer {
    margin-top: 16px;
    background-color: #879e9e;
    border-radius: 9px;
    width: 98vw;
    max-width: 340px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 14px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.row {
    display: flex;

    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

}

.row2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;

}

.smallSwitch {
    margin-right: 9px;
}

.hDivider {
    width: 100%;
    margin: 0;
    border: 1px solid;
    margin-top: 9px;
    margin-bottom: 6px;
    color: #A1A1B0;
}

.shortTxtInput {
    all: unset;
    width: 160px;
    text-align: left;
    margin-right: 9px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 400;
    font-size: 15px;
    height: 23px;
    border-radius: 3px;
    background-color: #F2F2F2;
    padding-left: 6px;
}

.greyInput2 {
    all: unset;
    width: 60%;
    text-align: left;
    margin-right: 9px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 400;
    font-size: 15px;
    height: 23px;
    border-radius: 3px;
    background-color: #F2F2F2;
    padding-left: 6px;
}