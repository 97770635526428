.main{
   display: flex;
   flex-direction: column;
    
}

.splitRow{
    display: flex;
    align-self: center;
    align-items: center;
    margin-bottom: 12px;
}
.txt{
    align-self: center;
    justify-self: center;
    margin-right: 6px;
    text-align: center;
    line-height: 100%;
    font-size: 14px;
}
.title{
    color: #9a81ff;
    margin: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}
.switch{
    color: #9a81ff;
}
.changePswd{
    align-self: center;
    font-weight: 700px;
    margin-top: 14px;
    display: flex;
    height: 28px;
    text-align: center;    
    justify-content: center;
    align-items: center;
    background-color: #FF3B30;
    box-shadow: var(--shd,0 1px 6px -3px rgba(0,0,0,.6));
    color: white;
    border-radius: 9px;
    width: 300px;
    cursor: pointer;
}

.socialPop {
    position: fixed;
    top: 23vh;
    left: 50vw;
    margin-left: -150px;
    width: 300px;
    
    border: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-self: center;
    align-self: center;
    align-content: center;
    background-color: white;
    max-width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
    border-radius: 25px;
}

.popBackground {
    align-self: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.333);
    top: 0;
    height: 100vh;
    width: 100vw;
}
.closeCont {
    width: 100%;
}

.closeSocialPop {
    margin-top: 12px;
    margin-left: 86%;
    right: 0;
    cursor: pointer;
}
.titleS {
    margin-top: 0px;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
   

}
