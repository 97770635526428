.main {
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 100vw;
    min-height: min-content;
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
.title {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;

}
.mainC {
    height: fit-content;
    width: 100vw;
    max-width: 500px;    
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-self: center;
    align-items: center;
    background-color: white;
    justify-self: center;
    align-self: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
}
.topRow{
    margin-top: 33px;
    margin-bottom: 33px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

}
.row{
    display: flex;
}
.region{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    padding: 6px;
    border-radius: 6px;
    width: 200px;
    min-width: max-content;
    margin-top: 6px;

}
.statCont{
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    padding: 6px;
    border-radius: 6px;
    text-align: center;
    margin-top: 16px;

}
.count{
    margin-left: .5em;
}
.chartIterator{
    all: unset;
    margin-left: 12px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.chartIterator:hover{
    background-color: rgb(226, 226, 226);
}
.chartIterator:disabled{
    background-color: rgb(197, 197, 197);
    cursor: not-allowed;
}
.chartCont{
    width: 100%;
}
.topRegions{
    font-weight: 600;
    text-align: center;
}
.chartTitle{
    font-weight: 600;
    font-size: 18px;
}