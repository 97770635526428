.main {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;    
    justify-content: center;/**centers text within box**/ 
    width: 90vw;
    max-width: 350px; 
    
    margin: 10px;
    box-shadow: 0px 0px 3px;
    border-radius: 8px;
    
}



.title {
    font-size: 22px;
    padding: 5px;
    flex: 1;
    flex-basis: 70%;
    box-sizing: border-box;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.title:hover{
    background-color: #8381BD;
    color: white;
}
.delete{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}


.edit,
.view,
.delete {
    color: whitesmoke;
    padding: 5px;
    flex: 1;
    flex-basis: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    
    color: rgba(0, 0, 0, 0.801);

}

.edit:hover,
.view:hover,
.delete:hover {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    transition: .01s;
    box-shadow: 0px 0px 6px gray;
}
.page{
    box-shadow: 0px 0px 8px;
}

