.outside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
}


.main {
    margin-top: 10vh;
   justify-self: center;
   align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    width: 350px;
    max-width: 90%;
    border-radius: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: rgb(255, 255, 255);
    height: 60vh;
    max-height: 500px !important;
}
.main input {
    margin-top: px;
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 10px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    background-color: #F2F2F2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: black;
    text-align-last: center;
    width: 220px;
}
.main input:focus {
    border: 2px solid #9a81ff;
    outline: none;
    background-color: rgb(230, 230, 230);
}
.main input:hover {
    background-color: rgb(230, 230, 230);
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(29, 29, 29);
    opacity: 1; 
}


.p1{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: gray;
    margin-top: 12px;
    width: 220px;

}

.main button{
    margin-top: auto;
    margin-bottom: 10px; 
    color: white;
    background-color: #9a81ff;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border-width: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}
.main button:hover {
    background-color: #a781ff;
}
.main button:enabled {
    cursor: pointer;
}
.main button:disabled {
    cursor: not-allowed;
    color: white;
    background-color: grey;
}
.error {
    margin: 16px;
    margin-top: 23px;    
    font-weight: 400;
    color: #FF3B30;
    text-align: center;   
  }
.h1{
    color: #9a81ff;
    margin: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
}
.switch{
    color: #9a81ff;
}
.splitRow{
    display: flex;
    align-self: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.txt{
    align-self: center;
    justify-self: center;
    font-weight: 400;
    
    line-height: 100%;
    font-size: 16px;
}