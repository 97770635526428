.main2 {
    
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    top: 0;
    width: 100vw;
    max-width: 1020px;
    min-height: 56px;
    max-height: 56px;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    
    z-index: 9 !important;
}

.main {
    
    justify-self: center;
    align-self: center;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
    align-items: center;
    z-index: 9 !important;
    

}
.yologoicon{
   height: 90%;
   float: left;
}
.btnCont{
    width: 400px;
    display: flex;
    justify-content: space-evenly;

}
@media only screen and (max-width: 600px) {
    .btnCont {
       justify-content: flex-end;
       
    }
}
.plainBtn{
    all: unset;
    height: 33px;
    width: 100px;   
    text-align: center;
    align-items: center;
    line-height: 30px;
    font-size: 22px;
    font-weight: 500;
    border-radius: 9px;
    cursor: pointer;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.333);
}
.plainBtn:hover{
    text-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
}
.loginBtn{
    all: unset;
    color: white;
    background: linear-gradient(135deg, #818DFF 0%, #9A81FF 55.21%, #C081FF 100%);
    height: 33px;
    min-width: 100px;
    width: 100px !important;
    cursor: pointer;
    text-align: center;
    align-items: center;
    line-height: 30px;
    font-size: 22px;
    font-weight: 600;
    border-radius: 9px;
    margin-left: 16px;
    margin-right: 12px;
}