
   
  .box{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 80vw;
    height: 80vh;
    overflow: hidden;
    background-color: white;
    border-radius: 8px;
    align-self: center;
   box-shadow: 0px 0px 3px;
  }
   
  .closeIcon {
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: rgba(94, 94, 94, 0.171);
    height:16px;
    width:16px;border-bottom-left-radius: 8px;
    padding: 2px;
    content: 'x';
    cursor: pointer;
    justify-self: right;
    box-shadow: 0px 0px 2px;
  }
  .closeIcon:hover{
    background-color: red;
  }
  .profilePicBorder{    
    width: 60px;
    height: 60px;       
    align-self: center;    
    box-shadow: 0px 0px 3px;
    border-radius: 50%;
    margin-top: 3px;
    margin-bottom: 3px;
    overflow:hidden;
    align-items: center;
}
.profilePic{
    display: flex;  
    height: 100%;
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    
}
  .pageName{
    align-self: center;
  }
  .form{
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .phoneContainer{

    margin: 3px;
    align-self: center;
    justify-self: center;
    box-shadow: black 0px 0px 2px;
   border-radius: 3px;
  }
  .input{
   margin: 3px;
   align-self: center;
   justify-self: center;
   border: none;
   box-shadow: black 0px 0px 2px;
   border-radius: 3px;

  }
  .input:focus{
    border-color: #A082FC;
  }
  .upload{
    width: 30%;
    justify-self: flex-end;
    align-self:center;
    margin-top:6px;
    background-color: #A082FC;
    color: white;
    border: none;
    border-radius: 3px;
    box-shadow: black 0px 0px 1px;
    cursor: pointer;
  }