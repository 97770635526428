.ColorPickerCont{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    z-index: 11;
}
.SketchPicker{
   
}