.main { 
  
  margin-top: 0;
  top: 0;
  
  min-height: min-content;
  height: 100%;
  
  width: 100vw;
  max-width: 100vw;
  background-color: rgba(245, 245, 245, 0.24);
}