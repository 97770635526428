.main{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    padding-left:30px;
    padding-right: 30px;
    padding-bottom: 6px;
    flex-direction: column;
    border-radius: 24px;
    box-shadow: var(--shd,0 2px 4px rgba(0,0,0,.6));
    color: rgb(31, 31, 31);
    background-color: #fdfdfd;
    justify-self: center;
    align-self: center;
    width: 300px;
    max-width: 90%;
   top: 0;
   margin-top: 66px;
   padding-bottom: 16px;
   z-index: 13;
  
}
.profilePicBorder{
    max-height: 40%;
    max-width: 60%;
    min-width: 90px;
    min-height: 90px;       
    align-self: center;
     box-shadow: var(--shd,0 1px 9px -3px rgba(0,0,0,.6));
    border-radius: 50%;
    margin-top: 0px;
    margin-bottom: 12px;
    overflow:hidden;
}
.profilePic{
    display: flex;    
    width: 90px;
    height: 90px;   
    align-self: center;
    justify-content: center;
    align-items: center;    
}
 .image{
   height: 100%;
   width: auto;
}
.form{
    display: flex;
    flex-direction: column;
}
.closeIcon {
    margin-top: 20px;
    margin-right: -2px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    height:23px;
    width:23px;
    padding: 0px;
    content: 'x';
    cursor: pointer;
    justify-self: right;
    
   
  }
  .closeIcon:hover{
    size: 3;
  }
.input{
    margin-bottom: 18px;
    height: 30px;
    align-self: center;
    width: 300px;
    max-width: 292px !important;
    
    background-color: white;
    border: none;
   
    text-align: left;
    align-items: left;
    border-radius: 9px;
    place-items: left;
    padding-left: 6px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    -webkit-appearance: none;
    -moz-box-shadow: rgba(0, 0, 0, 0.392) 0px 1px 3px;
    
    
    
}
.mobilePhone{
    
    width: 40px;
}
.PhoneInput{
    min-height: 23px;
    width: min-content;
    align-self: center;
    border: none;
  
}
.connect{
    margin-top: 3px;
    align-self: center;
    font-size: large;
    font-weight: bold;
    margin-bottom: -12px;
}
.enter{
    align-self: center; 
    text-align: center;
    margin-top: 18px;
    margin-bottom: 16px;
    font-weight: 500;
}
.andOr{
    align-self: center; 
    text-align: center;
    font-weight: 500;
    margin-bottom: 12px;

}
::placeholder{
    color:grey;
}
.agreeContainer{
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 6px;
}
.agreeTxt{
    font-size: small;
    margin-left: 3px;
}
.underline{
    text-decoration: underline;
    cursor: pointer;
}
.checkCont{
    display: flex;
    width: 30px;
    align-items: center;
}
.checkBx{
  /*  min-height: 22px !important;
    height: 22px !important;
    width: 22px !important;
    font-size: 23px;*/
    width: 2em;
    height: 1.2em;
    background-color: white;
    font-size: inherit;
    border: none;
    cursor: pointer;
    

}
.checkBx::selection{
    background-color: #34C759;
}

.checkBx input:checked{
    background-color: #34C759;
    color: #34C759;
}
.checkBx:disabled{
    cursor:not-allowed;
}
.checkBx:checked{
    color: green;
}

.xConBtn{
    font-size: 16px;
    margin-top: 12px;
    justify-self: center;
    align-self: center;
 width: 100px;
 background-color: #34C759;
 color: white;

 padding-left: 12px;
 padding-right: 12px;
 border-radius: 9px;
 align-content: center;
 align-items: center;
 cursor: pointer;
 min-width: max-content;
 height: 30px;
 font-weight: 600;
 margin-bottom: 12px;
 border:none;
 box-shadow: var(--shd,0 1px 6px -3px rgba(0,0,0,.6));
}
.xConBtn:hover{
    box-shadow: var(--shd,0 1px 4px rgba(0,0,0,.6));
    box-shadow: var(--shd,0 2px 9px -2px rgba(0,0,0,.6));
}
.xConBtn:disabled{
    background-color: #FF3B30;
    cursor:not-allowed;
}   