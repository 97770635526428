.popBackground {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.333);
  top: 0;

  min-height: 200%;
  width: 100vw;
}

.cropPop {
  position: fixed;
  top: 10vh;
  width: 96vw;
  max-width: 500px;
  border: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center;
  background-color: white;

  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
  border-radius: 26px;

  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

}

.cropBtn {

  align-self: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #34C759;
  color: white;
  box-shadow: 0px 0px 8px;
  height: 30px;
  min-width: fit-content;
  background-color: #34C759;
  align-items: center;
  color: white;
  border: none;
  border-radius: 9px;

  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 12px;
  width: 206px;
  max-width: 96%;
}

.Croppie {
  position: absolute;
  margin-top: 10vh;
  width: 90%;
  max-width: 500px;
  height: 50vh;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  align-items: center;

}

.ReactCrop {
  width: 500px;
  height: 400px;
  z-index: 3;
}

.outerImgCont {
  margin-top: 6px;
  height: 300px;
  width: 90%;
  max-width: 500px;
  background-color: white;

}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}


.box {
  position: relative;
  top: 40px;
  left: 50vw;
  margin-left: -150px;
  width: 300px;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-content: center;
  background-color: white;
  max-width: 100%;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.192);
  border-radius: 25px;
  padding-left: 9px;
  padding-right: 9px;
}


.closeCont {
  width: 100%;
  height: 33px;
}

.closeSocialPop {
  margin-top: 10px;
  margin-left: 90%;
  right: 0;
  cursor: pointer;
}

.closeIcon:hover {
  background-color: red;
}

.titleS {
  margin-bottom: 12px;
  margin-top: 2px;
  font-weight: 700;
  font-size: 24px;
  text-align: center;

}

.profilePicBorder {
  width: 60px;
  height: 60px;
  align-self: center;
  box-shadow: 0px 0px 3px;
  border-radius: 50%;
  margin-top: 3px;
  margin-bottom: 3px;
  overflow: hidden;
  align-items: center;
}